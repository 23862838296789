<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Clienti </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Elenco Clienti</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="primary" class="mr-2" @click="$router.push('/customer/search')"> Cerca Cliente </b-button>
          <b-button variant="primary" @click="$router.push('/customer/add')"> Aggiungi Cliente </b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500"/>
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="customerProvider"
        :busy="loading"
        :filter="filter"
        :current-page="currentPage"
        striped
        show-empty
        responsive
        @sort-changed="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #cell(tipologia)="data">
          {{ data.item.cliente_tipologia }}
        </template>
        <template #cell(nome)="data">
          {{ data.item.cliente_nome }} {{ data.item.cliente_cognome }}
        </template>
        <template #cell(ragione_sociale)="data">
          {{ data.item.cliente_ragionesociale }}
        </template>
        <template #cell(provincia)="data">
          {{ data.item.cliente_provincia }}
        </template>
        <template #cell(città)="data">
          {{ data.item.cliente_citta }}
        </template>
        <template #cell(data_nascita)="data">
          {{ data.item.cliente_nascita_data }}
        </template>
        <template #cell(targhe_auto_acquistate)="data">
          {{ data.item.targhe_auto_acquistate }}
        </template>
        <template #cell(opzioni)="data">
          <span class="text-nowrap">
            <feather-icon class="cursor-pointer mr-2" icon="EyeIcon" size="20" @click="$router.push({path: '/customer/' + data.item.id_customer})"/>
            <feather-icon class="cursor-pointer" icon="EditIcon" size="20" @click="$router.push({name: 'customer_detail', params: {id: data.item.id_customer, edit: 'edit'} })"/>
          </span>
        </template>
      </b-table>

      <b-pagination
        v-if="currentPage > 1"
        v-model="currentPage"
        :total-rows="customers.count"
        :per-page="perPage"
        align="right"
        size="sm"
        class="mt-2"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  BFormInput,
  BPagination,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
    BFormInput,
    BPagination,
  },

  data: () => ({
    fields: [
      {
        key: "id_customer",
        label: "ID Cliente",
        sortable: true,
      },
      {
        key: "tipologia",
        sortable: true,
      },
      {
        key: "nome",
        sortable: true,
      },
      {
        key: "ragione_sociale",
        sortable: true,
      },
      {
        key: "provincia",
        sortable: true,
      },
      {
        key: "città",
        sortable: true,
      },
      {
        key: "data_nascita",
        sortable: true,
      },
      {
        key: "targhe_auto_acquistate",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    customers: [],
    sortBy: 'id_customer',
    sortDesc: false,

    //Helpers

    loading: false,
    perPage: 15,
    currentPage: 1,
  }),

  methods: {
    async getCustomerList(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getCustomerList(request_data);
        this.customers = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async customerProvider() {
      await this.getCustomerList();
      return this.customers.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
